import React from 'react'
import './Vision.css'
import vision from '../../images/vision.png'

const Vision = () => {
    return (
        <div className="vision-bg" id="visions">
            <div className="container">
                <div className="vision display-flex">
                    <div className="vision-text">
                        <h3>Our Vision</h3>
                        <p>We provide next-generation end-to-end banking services and solutions covering all Temenos capabilities. With our vast experiences and expertise of many years, we are committed to offering you the best possible solution in the market. We are eager to help financial institutions, despite their sizes. Hence we want to be part of your success story.</p>
                    </div>
                    <div className="vision-image">
                        <img src={vision} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vision
