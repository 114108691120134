import React from 'react'
import './Notfound.css'
import { Link } from 'react-router-dom'

const Notfound = () => {
    return (
        <div className="top-page-padding-top height flex-page">
            <h1>page not found !</h1>
            <Link to="/" className="notfound-btn">Back to Homepage</Link>
        </div>
    )
}

export default Notfound
