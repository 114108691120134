import React from 'react'
import './Top_page2.css'
import {Link} from 'react-router-dom'
import { FaPlayCircle } from 'react-icons/fa';


const Top_page2 = () => {
    return (
        <div className="top-page-padding-top">
            <div className="top-page-main-div">
                <div className="main-addendtech-container">
                    <div className="main-addendtech">
                        <div className="main-addendtech-text">
                            <h1>Addendtech</h1>
                            <p>The Latest Digital Banking Technology <br /> Within Reach...</p>
                        </div>
                        <div className="btn-corner">
                            <Link to="/services/t24-transact" className="normal-button">Explore</Link>
                            <Link to="/" className="play-button"><FaPlayCircle className="top-space" size="18px" /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Top_page2
