import core from '../images/s-core.jpg';
import local from '../images/s-local.jpeg';
import migration from '../images/s-migration.gif';
import payment from '../images/s-payment.png';
import training from '../images/s-training.jpg';
import testing from '../images/s-testing.jpg';
export  const  informations = [
    {
        url: 't24-transact',
        image: core,
        heading: 'T24 transact',
        paragraph: "Addendtech Core Banking provides extensive banking knowledge and expertise to enable you to strengthen your core banking capabilities with innovative and cutting-edge technology. With Temenos Transact, the most successful and widely used digital core-banking solution globally, Addendtech ensures the most diverse banking capabilities across retail, corporate, treasury, wealth and payments modules using cloud-native agnostic technology. Here in Addendtech, we cover all the T24 modules as well as Islamic banking facilities.",
        list: "•	Accounts ",list2: "•	Agent & Broker ",list3: "•	Cash Management ",list4: "•	Customer Output ",list5: "•	Deposits ",list6: "•	Derivatives & Structured Products ",

        list7: "•	External Arrangements ",list8: "•	Framework ",list9: "•	General Ledger & Accounting ",list10: "•	Infrastructure ",list11: "•	Islamic Banking",list12: "•	Lending ",list13: "•	Limits & Collateral ",

        list14: "•	Product Management ",list15: "•	Reconciliation & Matching ",list16: "•	Securities",list17: "•	Staff Channel ",list18: "•	Trade Banking ",list19: "•	Trade Management ",list20: "•	Treasury"
    },
      {
        url: 't24-customization',
        image: local,
        heading: 'T24 Customization',
        paragraph: "We ensure your region-specific banking business model. Customize your applications based on demand and Regulatory Compliance. ",
        list: "•	Business analysis",list2: "•	Change request & enhancements",list3: "•	Local/offshore customization capabilities",

        list4:"•	Customer Information", list5:"•	Customer Tax Regulation ", list6:"•	IFRS", list7:"•	Financial Risk Management", list8:"•	Tax",list9:"•	Data Protection and Consent", list10:"•	Payment Regulations", 
    },
      {
        url: 'migration',
        image: migration,
        heading: 'migration',
        paragraph: "Migrate your legacy system to a more enhanced T24 UXP. With proficiency and expertise in these areas, we provide a seamless transition. Also, in accordance with demands, banks frequently upgrade their services to offer new and better functionalities, deal with regulatory changes, and, most importantly, keep up to date with technological advancements, reduce cost, and improve security. Temenos regularly upgrades its applications to address the present and future requirements of the banks and their customers. In Addendtech, we are committed to bringing you the latest upgrades. Our consultants are extremely qualified and experienced in assessing your needs to upgrade to future releases. In birds-eye view, we are capable enough to deal with any kind of upgrade that you may require. ",
        morePar: "Legacy to UXP TAFC to TAFJ Database Migration. "
      },
      {
        url: 'payments',
        image: payment,
        heading: 'payments',
        paragraph: "Next-generation payments on the number 1 technology platform in banking at Temenos with Addendtech provide you with advanced SaaS next-generation payments processing. We leverage the strategic technological advancement of temenos using its end-to-end functionality with the most advanced Cloud-Native, Cloud-Agnostic, AI, and API-first payments technology. With agile, open and robust architecture, we provide you with a faster and more reliable payment processing hub than any other industry. (Number 1 in IBS League for new Payment Hub licenses in 2019 & 20 - Temenos).Our services include Mobile payment solutions, Third-party payment, and payment gateway."
      },
      {
        url: 'testing',
        image: testing,
        heading: 'Testing',
        paragraph: "We ensure our clients' seamless experience across all platforms. To achieve this seamless experience, all of our products go under a rigorous testing process.The testing will ensure operations are being executed at their finest level and expected behavior within the system; therefore, the clients can utilize it as they see fit. The process includes",
        list:"•	Unit Testing ", list2:"•	Integration Testing ", list3:"•	Regression Testing ", list4:"•	Smoke Testing ", list5:"•	Functional test automation", list6:"•	Alpha Testing ", list7:"•	Beta Testing ", list8:"•	System Testing ", list9:"•	Stress Testing ", list10:"•	Performance Testing",
      },
      {
        url: 'training',
        image: training,
        heading: 'training',
        paragraph: "Our products are designed in a way that the end-user can adapt to the environment as fast as possible with minimum effort. Addendtech ensures that the end-users are being adequately trained to use the applications efficiently. Our comprehensive training programs are meant to reduce slip-ups and gather confidence in the workforce. During the training sessions, we make sure the end-users can understand the features and the functionality of our products so that they can carry out their duty smoothly. In addition to that, operating procedures, user guides, manuals, and other documentation are provided as well."
      },

]