import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { FaCaretDown } from 'react-icons/fa';
import './Services_list.css'
import image from '../images/15.png'
import image2 from '../images/q5.png'
import image3 from '../images/bg1111.png'
import { informations } from './informations'

const Services_list = ({ match }) => {
    return (
        <div className="leftbar-background">
            <div className="s-bg"><h1>Services</h1></div>
            <div className="all-service-container">
                <div className="leftbar">
                    <div>
                        <h4 className="centerit">Services</h4>
                    </div>
                    <ul className="services-list activated">
                        <li><NavLink to="/services/t24-transact" activeStyle={{
                            fontWeight: "bold",
                            color: "#3a8ac5"
                        }}>T24 Transact - Core Banking </NavLink></li>
                        <li><NavLink to="/services/t24-customization" activeStyle={{
                            fontWeight: "bold",
                            color: "#3a8ac5"
                        }}>T24 Customization</NavLink></li>
                        <li><NavLink to="/services/migration" activeStyle={{
                            fontWeight: "bold",
                            color: "#3a8ac5"
                        }}>Migration</NavLink></li>
                        <li><NavLink to="/services/payments" activeStyle={{
                            fontWeight: "bold",
                            color: "#3a8ac5"
                        }}>Payments</NavLink></li>
                        <li><NavLink to="/services/testing" activeStyle={{
                            fontWeight: "bold",
                            color: "#3a8ac5"
                        }}>Testing</NavLink></li>
                        <li><NavLink to="/services/training" activeStyle={{
                            fontWeight: "bold",
                            color: "#3a8ac5"
                        }}>Training</NavLink></li>
                        {/* <div className="icon-text" onClick={handleDropdown}>
                            <h4>More Services</h4>    
                            <FaCaretDown  className="service-icon"/>
                        </div>
                   
                   <ul className={!dropdown ? "services-list" : "services-list activated"}>
                       <li><Link to="islamic-banking">Islamic Banking</Link></li>
                       <li><Link to="finance-inclusion">Finance Inclusion</Link></li>
                       <li><Link to="digital-front-office">Digital Front Office(Temenos-infinity)</Link></li>
                       <li><Link to="triple-a-plus">Triple A Plus</Link></li>
                       <li><Link to="multifonds">Multifonds</Link></li>
                       <li><Link to="temenos-payment-hub">Temenos Payments Hub(TPH)</Link></li>
                       <li><Link to="financial-crime-mitigation">Financial Crime Mitigation(FCM)</Link></li>
                       <li><Link to="analysis">Analysis</Link></li>
                       <li><Link to="ifrss">IFRSS</Link></li>
                       <li><Link to="financial-support">Financial Support</Link></li>
                   </ul> */}
                    </ul>
                </div>


                {informations.filter(person => person.url == match.params.postId).map(filteredPerson => (
                    <div className="services-content">
                        <img src={filteredPerson.image} alt="" />
                        <div className="services-list-text"><br />
                            <h3>{filteredPerson.heading}</h3>
                            <p>{filteredPerson.paragraph}</p>
                            <p>{filteredPerson.morePar}</p>
                        </div>
                        <br />
                        <div className="row-list">
                            <div className="f-col">
                                <li>{filteredPerson.list}</li>
                                <li>{filteredPerson.list2}</li>
                                <li>{filteredPerson.list3}</li>
                                <li>{filteredPerson.list4}</li>
                                <li>{filteredPerson.list5}</li>
                                <li>{filteredPerson.list6}</li>
                                <li>{filteredPerson.list7}</li>
                            </div>
                            <div className="f-col">
                                <li>{filteredPerson.list8}</li>
                                <li>{filteredPerson.list9}</li>
                                <li>{filteredPerson.list10}</li>
                                <li>{filteredPerson.list11}</li>
                                <li>{filteredPerson.list12}</li>
                                <li>{filteredPerson.list13}</li>
                                <li>{filteredPerson.list14}</li>
                            </div>
                            <div className="f-col">
                                <li>{filteredPerson.list15}</li>
                                <li>{filteredPerson.list16}</li>
                                <li>{filteredPerson.list17}</li>
                                <li>{filteredPerson.list18}</li>
                                <li>{filteredPerson.list19}</li>
                                <li>{filteredPerson.list20}</li>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}


export default Services_list
