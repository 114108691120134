import React from 'react'
import './Footer.css'
import { FaLinkedin, FaFacebookSquare , FaTwitterSquare,FaMapMarkerAlt, FaPhoneAlt, FaMapMarkedAlt , FaEnvelope} from 'react-icons/fa';
import {  } from 'react-icons/md';
import { GrMail } from "react-icons/md";
import { Link } from "react-router-dom";
import { Email, Item, Span, A, renderEmail } from 'react-html-email'

const Footer = () => {
    return (
        <div className="bg-footer">
            <div className="container">
            <div className=" display-flex footer">
                <div className="address">
                    <p><FaMapMarkedAlt /> <a>11 Sam Leong road​ #01-12</a></p>
                    <p><FaMapMarkerAlt /> <a> TRIO SINGAPORE - 207903</a> </p>
                    <p><FaEnvelope /> <a href = "mailto:info@addendtech.com"> info@addendtech.com</a></p>
                    <p><FaPhoneAlt /> <a href="tel:+006569806260"> +00 65 6980 6260</a>​</p>
                    
                    

                    
                </div>
                <div className="services">
                        <li><Link to="/services/t24-transact">T24 Transact - Core Banking </Link></li>
                        <li><Link to="/services/t24-customization">T24 Customization</Link></li>
                        <li><Link to="/services/migration">Migration</Link></li>
                        <li><Link to="/services/payments">Payments</Link></li>
                        <li><Link to="/services/testing">Testing</Link></li>
                        <li><Link to="/services/training">Training</Link></li>
                </div>
                <div className="social">
                    <p><b>Visit us via : </b></p>
                    <div className="icons">
                        <a href="https://www.facebook.com" target="_blank"><FaFacebookSquare className="icon" /></a>
                        <a href="https://www.linkedin.com" target="_blank"><FaLinkedin className="icon" /></a>
                        <a href="https://www.twitter.com" target="_blank"><FaTwitterSquare className="icon" /></a>
                    </div>
                </div>
            </div>
        </div>
            <h6>Copyright © 2021 Addendtech. All Right Reserved.</h6>
        </div>
    )
}

export default Footer
