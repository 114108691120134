import React from 'react'
import './Logo.css'
import icon1 from '../../images/devopss.png'
import icon2 from '../../images/11.png'
import icon3 from '../../images/12.png'
import icon4 from '../../images/13.png'
import icon5 from '../../images/14.png'
import icon6 from '../../images/15.png'
import icon7 from '../../images/16.png'

const Logo = () => {
    return (
        <div className="bg-color logo-content">
            <div className="top-part">
                <p>Latest Banking Technology With Addendtech</p>
            </div>
            <div className=" display-flex-logo">
                <div className="logo-box make-it-small">
                    <img src={icon1} alt="" />
                    <p>Devops</p>
                </div>
                <div className="logo-box">
                    <img src={icon2} alt="" />
                    <p>Integration</p>
                </div>
                <div className="logo-box">
                    <img src={icon3} alt="" />
                    <p>Cloud</p>
                </div>
                <div className="logo-box">
                    <img src={icon4} alt="" />
                    <p>Open Banking</p>
                </div>
                <div className="logo-box">
                    <img src={icon5} alt="" />
                    <p>Services</p>
                </div>
                <div className="logo-box">
                    <img src={icon6} alt="" />
                    <p>Testing</p>
                </div>
                <div className="logo-box">
                    <img src={icon7} alt="" />
                    <p>Training</p>
                </div>
            </div>
        </div>
    )
}

export default Logo
