import React,{ useState } from 'react'
import './All_services.css'
import { Link } from "react-router-dom";
import { data } from '../Home/Services/data'


const All_services = () => {
    const [searchItem, setSearchItem] = useState('')
    return (
        <div className="career-margin">
           <div className="all-services-container">
            <div className="s-bg"><h1>Services</h1></div>
               <div className="top-portion">
                   <input className="search" type="text" placeholder="Search..." onChange={event => {setSearchItem(event.target.value)}}/>
               </div>
               <div className="services-holder">
               <div className="rows">
                    {data.filter((val) => {
                        if(searchItem == ''){
                            return val;
                        }
                        else if(val.heading.toLowerCase().includes(searchItem.toLowerCase())){
                            return val;
                        }
                    }).map((info, key) => {
                        return<Link to={`/services/${info.id}`}>
                        <div className="services-col">
                        <img src={info.image} alt="" />
                        <div className="services-text">
                            <h4>{info.heading}</h4>
                            <p>{info.text}</p>
                        </div>
                    </div>
                    </Link> 
                    })}
                </div>
               </div>
           </div>
        </div>
    )
}

export default All_services
