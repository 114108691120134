import React,{ useState } from 'react'
import './Contact.css'
import { useDispatch } from 'react-redux'
import { createPost } from '../../actions/posts';

const Contact = () => {
    const [postData, setPostData] = useState( {email: "", subject:"", message:""} );
    const dispatch = useDispatch();
    const handleSubmit = (e) =>{
        e.preventDefault();

        if(postData.email == ""){
            alert('PLease enter email id')
        }
        else{
            
            if(postData.subject == "" && postData.message == ""){
                alert("Please provide subject or message")
            }
            else{
                dispatch(createPost(postData))
                setPostData({ email: '', subject: '', message: '' })
                alert('Email sent Successfully')
            }

        }
        
    }
    return (
        <div className="page-container">
            <div className="main-input-field">
                <form autoComplete="off"  onSubmit={handleSubmit}>
                    <input name="email" type="email" placeholder="Enter Email*" value={postData.email} onChange={(e) => setPostData({ ...postData, email: e.target.value})}/><br /><br />
                    <input name="subject" type="text" placeholder = "Subject*" value={postData.subject} onChange={(e) => setPostData({ ...postData, subject: e.target.value})}/><br /><br />
                    <textarea name="message" id="" cols="30" rows="10" placeholder ="Your Message*" value={postData.message} onChange={(e) => setPostData({ ...postData, message: e.target.value})}></textarea><br /><br />
                    <button className="normal-button "type="submit">Submit</button>
                </form>
            </div>
        </div>
    )
}

export default Contact
