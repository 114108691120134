import q1 from '../../images/Core-banking.jpg'
import q2 from '../../images/development.jpg'
import q3 from '../../images/migration.jpg'
import q4 from '../../images/payment.jpg'
import q5 from '../../images/testing.jpg'
import q6 from '../../images/training.jpg'
export  const  data = [
{
    id:'t24-transact',
    td: 0,
    image:q1,
    heading:'T24 Transact',
},
{
    id:'t24-customization',
    td: 1,
    image:q2,
    heading:'T24 customization',
},
{
    id:'migration',
    td: 2,
    image:q3,
    heading:'Migration',
},
{
    id:'payments',
    td: 3,
    image:q4,
    heading:'Payments',
},
{
    id:'testing',
    td: 4,
    image:q5,
    heading:'Testing',
},
{
    id:'training',
    td: 5,
    image:q6,
    heading:'Training',
},


]