import React from 'react'
import './Solutions.css'

const Solutions = () => {
    return (
        <div className="top-page-padding-top height">
            <h1>This page is under developement !</h1>
        </div>
    )
}

export default Solutions
