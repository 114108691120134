import React, { useState } from 'react'
import './Navbar.css'
import { FaBars, ImMenu, FaCaretDown, FaChevronRight } from 'react-icons/fa';
import logo from '../images/code.png'
import { Link } from "react-router-dom";

const Navbar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => { setClick(!click) }

    const [clicks, setClicks] = useState(false);
    const handleClicks = () => { setClicks(!clicks) }
    return (
        <header>
            <div class="nav-container">
                <div class="logo-container">
                    <div className="sub-logo">
                        <Link to="/">
                            <img src={logo} alt="" height="30px" />
                        </Link>
                        <p><Link to='/'>ADDENDTECH</Link></p>
                    </div>
                </div>

                <div class={!click ? "nav-btn" : "nav-btn active"}>
                    <div class="nav-links">
                        <ul>
                            <li class="nav-link">
                                <Link to="/" onClick={handleClick}>Home</Link>
                            </li>
                            <li class="nav-link" >
                                <Link to="#">Services <FaCaretDown color="#617D98" /></Link>
                                <div class={"dropdown"}>
                                    <ul>
                                        <li class="dropdown-link">
                                            <Link to="/services/t24-transact" onClick={handleClick}>T24 Transact</Link>
                                        </li>
                                        <li class="dropdown-link">
                                            <Link to="/services/t24-customization" onClick={handleClick}>T24 Customization</Link>
                                        </li>
                                        <li class="dropdown-link">
                                            <Link to="/services/migration" onClick={handleClick}>Migration</Link>
                                        </li>
                                        <li class="dropdown-link">
                                            <Link to="/services/payments" onClick={handleClick}>Payments</Link>
                                        </li>
                                        <li class="dropdown-link">
                                            <Link to="/services/testing" onClick={handleClick}>Testing</Link>
                                        </li>
                                        <li class="dropdown-link">
                                            <Link to="/services/training" onClick={handleClick}>Training</Link>
                                        </li>
                                        {/* <li class="dropdown-link">
                                         <Link to="/islamic-banking"onClick={handleClick}>Islamic Banking</Link>
                                    </li>
                                    <li class="dropdown-link">
                                        <Link to="/finance-inclusion"onClick={handleClick}>Finance Inclusion</Link>
                                    </li>
                                    <li class="dropdown-link">
                                        <Link to="/digital-front-office"onClick={handleClick}>Digital Front Office(Temenos-infinity)</Link>
                                    </li>
                                    <li class="dropdown-link">
                                        <Link to="/triple-a-plus"onClick={handleClick}>Triple A Plus</Link>
                                    </li>
                                    <li class="dropdown-link">
                                        <Link to="/multifonds"onClick={handleClick}>Multifonds</Link>
                                    </li>
                                    <li class="dropdown-link">
                                        <Link to="/temenos-payment-hub"onClick={handleClick}>Temenos Payments Hub(TPH)</Link>
                                    </li>
                                    <li class="dropdown-link">
                                        <Link to="/financial-crime-mitigation"onClick={handleClick}>Financial Crime Mitigation(FCM)</Link>
                                    </li>
                                    <li class="dropdown-link">
                                        <Link to="/analysis"onClick={handleClick}>Analysis</Link>
                                    </li>
                                    <li class="dropdown-link">
                                        <Link to="/ifrss"onClick={handleClick}>IFRSS</Link>
                                    </li>
                                    <li class="dropdown-link">
                                        <Link to="/financial-support"onClick={handleClick}>Financial Support</Link>
                                    </li> */}
                                    </ul>
                                </div>
                            </li>
                            <li class="nav-link" >
                                <Link to="/solutions" onClick={handleClick}>Solutions</Link>
                            </li>
                            <li class="nav-link" >
                                <Link to="/career" onClick={handleClick}>Career</Link>
                            </li>
                            {/* <li class="nav-link" >
                            <Link to="/insights" onClick={handleClick}>Insights</Link>
                        </li> */}
                            <li class="nav-link nav-contact" >
                                <Link to="/contact-us" onClick={handleClick}>Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="hamburger-menu-container">
                    <div class="hamburger-menu " onClick={handleClick}>
                        <div>{click ? <FaChevronRight size="1.5rem" color="#3a8ac5" /> : <FaBars size="1.5rem" color="#3a8ac5" />}</div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Navbar
