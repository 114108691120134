import React,{ useState } from 'react'
import { Link } from "react-router-dom";
import './Services.css'
import { data } from './data'


const Services = () => {
    const [state] = useState(data)
        
    return (
        <div className=" bg-color">
            <div className="service-container">
                <h4 className="text-center">Our Services</h4><br />
                <div className="dash"></div>
                <div className="row">
                    {(() => {
                        let posts = [];
                        for (let i = 0; i < state.length - 0; i++) {
                            if(i >5){
                                posts.push(<Link className="left-position" to='/services'>See more...</Link>)
                            }
                            else{
                                posts.push(
                                    <Link to={`/services/${state[i].id}`}>
                                        <div className="services-col">
                                            <img src={state[i].image} alt="" />
                                            <div className="services-text">
                                                <h4>{state[i].heading}</h4>
                                            </div>
                                        </div>
                                    </Link>)
                                }
                        }
                        return posts;
                    })()}
                </div>
            </div>
        </div>
    )
}

export default Services
