import React from 'react'
import './Career.css'
import career from '../images/career.png'
import career_bonus from '../images/career-bonus.png';
import career_timing from '../images/career-timing.png';
import career_workplace from '../images/career-workplace.png';
import devops from '../images/devops.png';
import brain from '../images/brain.png';
import pc from '../images/pc.png';

const Career = () => {
    return (
        <div className="career-margin career-bg">
            <div className="cereer-container">
                <div className="career-text-portion">
                    <div className="career-main-text">
                        <h1>Work Where</h1>
                        <h2>You Love</h2>
                        <p>Become a part of our team</p>
                        <p className="career-btn">Apply</p>
                    </div>
                    <div className="career-benefits-text">
                        <h1>Benefits</h1>
                    </div>
                </div>
                <div className="career-image-portion">
                    <img src={career} alt="" />
                </div>
            </div>
            {/* second part start */}
            <div className="second-container">
                <div className="cart-container">
                    <div className="career-cart">
                        <img src={career_bonus} alt=""  /><br />
                        <p>Yearly Bonus</p>
                    </div>
                    <div className="career-cart">
                        <img src={career_timing} alt="" /><br />
                        <p>Flexible Timing</p>
                    </div>
                    <div className="career-cart">
                        <img src={career_workplace} alt="" /><br />
                        <p>Happy Workplace</p>
                    </div>
                </div>
                <h1>Current Offerings</h1>
                <div className="main-apply-container">
                    <div className="apply-container">
                        <div className="apply-container-logo">
                            <img src={devops} alt="" />
                        </div><br />
                        <div className="apply-container-text">
                            <div className="plane-text">
                                <h3>Devops Engineer</h3>
                                <span>Lorem ipsum dolor, sit amet consectetur  adipisicing elit. Minima, ducimus!</span>
                                <p>Lorem ipsum dolor sit amet consectetur.</p>
                                <p className="apply-btn">Apply</p>
                            </div>
                        </div>
                    </div>
                    <div className="apply-container">
                        <div className="apply-container-logo">
                            <img src={brain} alt="" />
                        </div>
                        <div className="apply-container-text">
                            <div className="plane-text">
                                <h3>Devops Engineer</h3>
                                <span>Lorem ipsum dolor, sit amet consectetur  adipisicing elit. Minima, ducimus!</span>
                                <p>Lorem ipsum dolor sit amet consectetur.</p>
                                <p className="apply-btn">Apply</p>
                            </div>
                        </div>
                    </div>
                    <div className="apply-container">
                        <div className="apply-container-logo">
                            <img src={pc} alt="" />
                        </div>
                        <div className="apply-container-text">
                            <div className="plane-text">
                                <h3>Devops Engineer</h3>
                                <span>Lorem ipsum dolor, sit amet consectetur  adipisicing elit. Minima, ducimus!</span>
                                <p>Lorem ipsum dolor sit amet consectetur.</p>
                                <p className="apply-btn">Apply</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Career
