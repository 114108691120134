import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import './components/commonstyle.css'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Top_page2 from './components/Home/Top_page2/Top_page2';
import Services from './components/Home/Services/Services';
import Vision from './components/Home/Vision/Vision';
import Footer from './components/Footer/Footer';
import Logo from './components/Home/Logo/Logo';
import Description from './components/Description/Description';
import Pop_contact from './components/Pop_contact/Pop_contact';
import Services_list from './components/Services_list/Services_list';
import Career from './components/Career/Career';
import Contact from './components/Contact/Contact';
import Solutions from './components/Solutions/Solutions';
import Notfound from './components/Notfound/Notfound';

import { useDispatch } from 'react-redux';
import { createPost } from './actions/posts'
import All_services from './components/All_services/All_services';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(createPost());
  },[dispatch])
  return (
    <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact>
            <Top_page2 />
            <Vision />
            <Services />
            <Logo />
          </Route>
          <Route path="/services/:postId" component={Services_list} />
          {/* <Route path="/services" component={Pop_contact} /> */}
          <Route path="/career" component={Career} />
          <Route path="/solutions" component={Solutions} />
          <Route path="/contact-us" component={Contact} />
          <Route path="/services" component={All_services} />
          <Route path="*" component={Notfound}/>
        </Switch>
        <Footer />
    </Router>
  );
}

export default App;

